import './jquery-ui';

class DatePicker {
  constructor() {
    $('.datepicker').datepicker({
      dateFormat: 'yy-mm-dd'
    });
  }
}

export default DatePicker;
