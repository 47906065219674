// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import './src/jquery'

import DatePicker from './src/datepicker'
import Modal from './src/modal'

document.addEventListener('turbo:load', function() {
  $('.hamburger').on('click', function(e) {
    e.stopPropagation();
    $(this).toggleClass('hamburger__change');
    if ($(this).hasClass('hamburger__change'))
      $('.mega-menu').slideDown();
    else
      $('.mega-menu').slideUp();
  });
  new Modal();
  new DatePicker();
});
